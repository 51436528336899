.op-work-package-split-view
  height: 100%
  justify-content: space-around
  border-left: 1px solid var(--borderColor-muted)
  border-top: 1px solid var(--borderColor-muted)

  // border-top-left-radius: 8px
  // border-top-right-radius: 8px
  // border-right: 1px solid var(--borderColor-muted)
  // margin-right: 20px
